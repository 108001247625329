import { Link } from "gatsby";
import React from "react";
import useTitleContext from "../../context/useTitleContext";
import "./404.scss";

function FourOFour() {
  useTitleContext("ERROR404");
  return (
    <div className="FOF">
      <h2 className="FOF__h2">Die aufgerufene Seite existiert leider nicht</h2>
      <Link to="/" className="FOF__Link">
        weiter Surfen!
      </Link>
    </div>
  );
}

export default FourOFour;
